// .dropable-images-zone{
//     background: black;
//     padding: 10px;
// }

// .layout-main,.layout-main-container{
//     padding: 0  !important;
//     margin: 0 ;
//     background: #061426;
// }

.music-container{
    background: #061426;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow: auto;
}
#hello{
  padding: 0; 
  margin: 0;
  position: absolute !important;
}
.box{
    width: 100px;
    height: 100px;
    // position: fixed;
    // background: rebeccapurple;
    border: 5px solid red;
    font-size: 50px;
    text-align: center;
    // display: flex;
    // justify-content/: center;
    // align-items: center;
}

body{
    background: #0b213f;
}