.anime-scene {
    width: 100%;
    // height: 370px;
    background: rgb(0, 0, 0);
    justify-content: center;
    display: flex;
    // .safe-area{
    //     width: calc(50%);
    //     height: 360px;
    //     border: 2px solid rebeccapurple;

    // }
}


.target {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 150px;
    left: 100px;
    line-height: 100px;
    text-align: center;
    background: #ee8;
    color: #333;
    font-weight: bold;
    border: 1px solid #333;
    box-sizing: border-box;
}
.custom-group-origin-control{
    border-color: rgb(0, 0, 0);
    background: #ff0004;
    width: 11px !important;
    height: 11px !important;
    margin-top: -6px;
    margin-left: -6px;
    border-radius: 50% !important;
    z-index:1 !important;
    display: none;
}
.moveable-area{
    z-index: -1;
}
.moveable-line{
    z-index: -2 !important;
}
.moveable-control{
    z-index: -1 !important;
}
.moveable-origin{
    // z-index: -100000 !important;
    background-color: red !important;
    // left: 50px !important;
    pointer-events: auto !important;
    // opacity: 0;
 

}


.fk-bone {
    // border: rgb(93, 44, 141) 1px solid;
    color: white;
    // position: absolute;
    // background: rgb(255, 0, 0);
    // width: 100px;
    // height: 100px;
}

// .moveable-control{
//     width: 5px !important;
//     height:  5px !important;
// }


.zot-scene {
    background: black;
    width: 720px;
    height: 420px;
    justify-content: center;
    display: flex;
    margin: auto;
    z-index: 1 !important;
    position: relative;
}







  
//   /* The scrollbar track (the part the thumb moves along) */
// ::-webkit-scrollbar {
//     width: 10px; /* Width of the entire scrollbar */
//     height: 10px;
//   }
  
//   /* The scrollbar thumb (the draggable part) */
// ::-webkit-scrollbar-thumb {
//     background: #0037ff; /* Color of the scrollbar thumb */
//     border-radius: 10px; /* Round the corners of the scrollbar thumb */
//   }
  
  

.piano {
    display: flex;
    flex-direction: column;
    position: fixed;
    // width: 100vw; /* viewport width */
    height: 100vh; /* viewport height */
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 10;
    left: -10px;
}

.key {
    width: 60px;
    height: 20px;
    border: 1px solid #000;
    box-sizing: border-box;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    position: relative;
}

.white {
    background-color: #fff;
    color: black;
    z-index: 1;
}

.black {
    background-color: #000;
    color: #fff;
    height: 20px;
    // width: 40px;
    // position: absolute;
    // left: 40px; /* Adjust to align with white keys */
    // margin-left: -20px;
    // margin-right: -20px;
    z-index: 2;
    // top: 4px; /* Adjust to align with white keys */
}