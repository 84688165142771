

.packs-list{
    list-style: none;
    position: relative;
    left: -20px;
    
    li{
        width: 100%;
        padding: 5px 10px;
        // background: rgba(255, 255, 255, 0.089);
        margin-bottom: 5px;
        padding-bottom: 10px;
        cursor: pointer;
        border-bottom: 1px solid rebeccapurple;
    }
    li:active{
        opacity: 0.5;
        border-bottom: 1px solid rgb(64, 255, 0);
    }
}