.rst__rowTitle{
    color: black !important;
}


.moveable-control{
    // visibility: hidden !important;
    background-color: transparent !important;
    width: 10px !important;
    height: 10px !important;
    border-color: transparent !important;
}
.frame {
    height: 20px;
    // border: 1px solid red;
    position: absolute;
    /* Make it draggable */
    cursor: move;
    /* Default cursor */
    /* border-left: 4px solid red; */
    border-right: 3px solid rgb(0, 60, 255)  !important;
    background: rgb(0, 255, 149);
    // outline:1px solid black;
    // outline-offset: -4px;
}
// strong{
//     // width: calc(100% - 1px);
//     background: rgb(0, 0, 0) ;
//     height: 18px;
//     display: flex;
// }
// .strong-active{
//     background: rgb(2, 148, 46) !important;
// }
// *,
// body,
// html {
//     padding: 0;
//     margin: 0;
// }

// body {
//     background: rgb(44, 44, 44);
// }


.time-line-cursor{
    width: 100vw; /* viewport width */
    height: 100vh; /* viewport height */
    width: 2px;
    height: 292vh;
    background: rgb(255, 0, 0);
    position: absolute;
    z-index: 100;
    border: 1px solid red;
    top: -10px;
    // cursor: grab;

    overflow-x: scroll;
    overflow-y: hidden;
}

.beat-lines{
    display: flex;
    .line{
        position: absolute;
        width: 1.5px;
        height: 290.5vh;
        background: rgba(139, 89, 255, 0.453);
    }
    .its-a-bar{
        background: rgb(255, 128, 89) !important;
    }
}


.piano-bars{
    width: 2620px;
    
    width: 100vw; /* viewport width */
    height: 100vh; /* viewport height */
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    position: fixed;
    top: 32px;
    background: rgb(0, 0, 0);
    // left: 62px;
    right: 0;
    height: 20px;
    z-index: 1000;
    left: 0;
    .bars{
        display: flex;
        position:absolute;
        left: 61px;
    }
    .bar{
        flex: auto;
        // padding-left: 5px;
        position:absolute;
        // background: rebeccapurple;
        font-weight: bold;
        
        }
}

.timeline-control{
 
    // background: red;
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    
    
    // top: 600px;
    background: black;
    width: 100%;
    // height: 50px;
    padding: 2.5px 0;
    z-index: 10000;
   
    
    // flex-direction: row;
   
    .controls{
        left: 60px;
        position: relative;
        width: 100%;
 
        .close-button{
            position: fixed;
            // right: 0px;
            top: 10px;
            right: 10px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background: red;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            cursor: pointer;
        }
     
        .group{
            display: flex;
            // height: 30px;
            position: relative;
        }
        img{
            background: rgb(255, 255, 255);
            position: absolute;
            // left: -12px;
            top: -1px;
            height: 15px;
        }
        .duration{
            width: 150px;
            // background: rebeccapurple;
            display: flex;
            justify-content: center;
            align-items: center;
            .to{
                width: 40px;
                display: flex;
                justify-content: center;
            }
            .start,.end{
                background: rgb(0, 0, 0);
                border: 1px solid rgb(255, 255, 255);
                width: 50px;
                height: 20px;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .p-button{
    
        // background: rgb(8, 66, 255);
        font-size: 10px;
        border-radius: 2px;
        margin: 0  5px;
        padding: 5px 15px;
        margin-top: 5px;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: white;
        margin-left: 5px;
        cursor: pointer;
        pointer-events: stroke;
    }
}

.full-scene-timeline-container{
      user-select: none;
}
.timeline-container{

    z-index: 100;
    position: absolute;
    bottom: 0;
    left: 50px;
    right: 0;
    top: 50px;
    // height: 120px;
    height: 2160px;
    // max-height: 2000px;
    width:2563px;
    background: rgb(22, 22, 22);
//    overflow-y: auto;

  
    .timeline {
        height: inherit;
        border: 2px solid rgb(0, 0, 0);
        width: 100%;
        background-image:
            repeating-linear-gradient(#413f3f 0 1px, transparent 1px 100%),
            repeating-linear-gradient(90deg, #5d5d5d43 0 1px, transparent 1px 100%);
        background-size:20px 20px;
        // position: absolute;

        display: flex;
        .labels {
            .buttons{
                margin-right: 3px;
               
                .p-button{
                    // background-color: transparent;
                    // color: white;
                    border: 0;
                    padding: 8px;
                    margin-left: 5px;
                 
                    width: 8px;
                    height: 8px;
                    .pi{
                        font-size: 10px;
                    }
                }
            }
            background: rgb(0, 0, 0);
            width: 150px;
            color: rgb(238, 238, 238);
            // overflow: hidden;
 
             .label-active{
                background: rgb(2, 148, 46) !important;
             }
            .label {
                display: flex;
                align-items: center;
                padding-left: 10px;
                justify-content: space-between;
                width: 100%;
                height: 20px;
                font-size: 10px;
                // background: red;botto
                border-bottom: 1px solid rgb(255, 255, 255);
                font-weight: bold;
            }
        }
    
        .keyframes {
            position: relative;
            width: 100%;
            background-image:
                repeating-linear-gradient(#413f3f 0 1px, transparent 1px 100%),
                repeating-linear-gradient(90deg, #5d5d5d43 0 1px, transparent 1px 100%);
            background-size:20px 20px;
       
        }
    }
}
