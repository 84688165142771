.app-name {
    font-weight: bold;

    span {
        color: red;
    }

    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
}

.app-container {
    // background: rebeccapurple;
    width: 500px;
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    height: 100vh;

    .app-page {
        flex: auto;
    }

    .app-name {
        font-weight: bold;

        span {
            color: red;
        }

        text-align: center;
        font-size: 30px;
        margin-bottom: 10px;
    }

    .app-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //  background: rgb(7, 5, 27);
        padding: 0 10px;
        margin: 15px 0;

        button {
            border: 0;
            border-radius: 0;
            background: rebeccapurple;
            padding: 10px;
            color: white;
            font-weight: bold;
            border-radius: 20px;
        }

        button:hover {
            cursor: pointer;
        }

        button:active {
            opacity: 0.6;
        }
    }

    .btn {
        border: 0;
        border-radius: 0;
        background: rebeccapurple;
        padding: 10px;
        color: white;
        font-weight: bold;
        border-radius: 50%;
        margin-left: 10px;
    }
    .btn:hover {
        cursor: pointer;
    }
    
    .btn:active {
        opacity: 0.6;
    }

    button:hover {
        cursor: pointer;
    }

    button:active {
        opacity: 0.6;
    }
    .edit{
        background: rgb(38, 255, 0);
    }

    .delete{
        background: red;
    }
}


.button {
    border: 0;
    border-radius: 0;
    background: rebeccapurple;
    padding: 10px;
    color: white;
    font-weight: bold;
    border-radius: 20px;
}

.button:hover {
    cursor: pointer;
}

.button:active {
    opacity: 0.6;
}