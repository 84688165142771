.song-lab {
    width: 100%;
    // overflow: auto;
    display: flex;
    position: relative;

    // min-width: 500px; 
    .layers{
        background: rebeccapurple;
        margin-top: 2px;
        position: fixed;
        z-index: 10;
        // bottom: 0;
        // top: 0;
    
        // height: 100vh;
        // left: 0;
        // height: 400px;
        .layer {
            // position: absolute;
         
            width: 100px;
            background: rgb(15, 15, 15);
            padding-left: 10px;
            height: 20px;
            margin: 4px;
            margin-top: 0;
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: bold;

        }
    }
    // background: rebeccapurple;
    .patterns {
        // background: rgba(255, 255, 255, 0.097);
        // display: flex;
        align-items: center;
        margin-bottom: 5px;
        position: relative;
        left: 110px;
        width: 100%;
        // overflow: auto;
    

       

        .pattern {
            display: flex;
            flex: 1;
            .block {
                margin: 2px;
                width: 40px;
                display: flex;
                height: 20px;
                background: rgb(82, 210, 84);
            }

            .active-0 {
                background: rgb(210, 82, 82);

            }

            .active-1 {
                background: rgb(82, 210, 84);
            }
        }

    }
}